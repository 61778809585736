<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage">
                    <div class="not-authorized-content flex flex-column justify-center align-center">
                        <h2>You are not authorized to visit this page</h2>
                        <p>Please make sure you are logged in with correct rights.</p>
                        <button @click="$router.go(-1)" class="btn btn-primary alternative">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotAuthorized'
}
</script>

<style lang="scss">
.not-authorized-content {
    h2 {
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 10rem;
    }
}
</style>